import React from "react";
import { Provider } from "react-redux";
import { Route } from "react-router-dom";

import store from "./store";

import CartContainer from "./components/CartContainer";
import ContactContainer from "./components/ContactContainer";
import Footer from "./components/Footer";
import Home from "./components/Homepage";
import NavBar from "./components/NavBar";
import ProductDetailsContainer from "./components/ProductDetailsContainer";
import ProductsListContainer from "./components/ProductsListContainer";

import "./style/components/app.scss";
import "./style/global.scss";

export default function App() {
  // useEffect(()=> {
  //   loadReCaptcha();
  // },[])

  return (
    <Provider store={store}>
      <div className="wrapper">
        <Route path="/" component={NavBar} />
        <Route exact path="/producten" component={ProductsListContainer} />
        <Route exact path="/producten/product/:productId" component={ProductDetailsContainer} />
        {/* <Route exact path="/admin/orders" component={OrdersContainer} /> */}
        {/* <Route exact path="/admin/orders/:orderId" component={OrderDetailsContainer} /> */}
        <Route exact path="/cart" component={CartContainer} />
        <Route exact path="/contact" component={ContactContainer} />
        {/* <Route exact path="/admin" component={AdminPage} /> */}
        <Route exact path="/" component={Home} />
        <Route path="/" component={Footer} />
      </div>
    </Provider>
  );
}
